import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
      Enums.dateModes.currentMonth,
      Enums.dateModes.lastMonth,
      Enums.dateModes.lastThirtyDays,
      Enums.dateModes.previousThreeMonths,
      Enums.dateModes.previousTwelveMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Website
        'HospitalityWebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.hospitalityWebsiteOverview,
            filters: ['org', 'website-provider', 'device-type'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteProviderSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteProviderSummary, // org only
            filters: ['org'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
                'org', 'website-provider'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider', 'device-type'
            ],
            defaults: [
                'org'
            ],
            section: 'website',
            orgTypes: [1]
        }
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
          available: [1, 2],
          selected: [
            { value: 1, display: 'Provider 1' },
            { value: 2, display: 'Provider 2' }
          ],
          removable: true,
          lockable: true,
          locked: false
        },
    };
}
