import { IDateMode, DateMonthModes } from '../enums/enums';
import { environment } from '../../../environments/environment';

export interface SimpleFilterValue {
  value: string | number;
  display: string;
}

export type OrgEntityType = 'model' | 'class' | 'allclasses' | 'dealer' | 'lma' | 'virtual20' | 'orgcode1' | 'orgcode2' | 'orgcode3' | 'national' | 'dealer-group' | 'sales' | 'director';
export interface OrgFilterValue extends SimpleFilterValue {
  levelTypeId: number;
  entityType: OrgEntityType;
}

export interface DateFilterValue {
  dateModeId: number;
  displayName?: string;
  startDate: Date;
  endDate?: Date;
  previousStartDate: Date;
  previousEndDate: Date;
  previousYearStartDate: Date;
  previousYearEndDate: Date;
  orgDateCode: number;
}

export type FilterValueType = SimpleFilterValue | OrgFilterValue |  DateFilterValue;

export enum FilterTypes {
  org = 'org',
  date = 'date',
  digAdProvider = 'digad-provider',
  brand = 'brand',
  channel = 'channel',
  chatProvider = 'chat-provider',
  tradeInProvider = 'trade-in-provider',
  deviceType = 'device-type',
  digitalRetailingProvider = 'digital-retailing-provider',
  leadType = 'lead-type',
  profitCenter = 'profit-center',
  reputationPlatform = 'reputation-platform',
  reputationProvider = 'reputation-provider',
  saleType = 'sale-type',
  serviceSchedulerProvider = 'service-scheduler-provider',
  socialMediaPlatform = 'social-media-platform',
  socialMediaProvider = 'social-media-provider',
  sourceType = 'source-type',
  trait = 'trait',
  vehicleMake = 'vehicle-make',
  vehicle = 'vehicle',
  vehicleClass = 'vehicle-class',
  vehicleModel = 'vehicle-model',
  websiteProvider = 'website-provider',
  websiteMetricType = 'website-metric-type',
  digAdProgram = 'digad-program',
  dealerSize = 'dealer-size',
}

export interface FilterType {
  name: string;
  displayName: string;
}

export const filterTypeLookup: { [id: string]: FilterType } = {
  'org': { displayName: 'Org', name: 'org' },
  'brand': { displayName: 'Brand', name: 'brand' },
  'channel': { displayName: 'Channel', name: 'channel' },
  'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
  'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
  'device-type': { displayName: 'Device Type', name: 'device-type' },
  'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
  'digad-provider-v2': { displayName: 'Provider', name: 'digad-provider-v2' },
  'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
  'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
  'profit-center': { displayName: 'Profit Center', name: 'profit-center' },
  'digad-program': { displayName: 'Program', name: 'digad-program' },
  'reputation-provider': { displayName: 'Provider', name: 'reputation-provider'},
  'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
  'social-media-platform': { displayName: 'Platform', name: 'social-media-platform' },
  'service-scheduler-provider': { displayName: 'Provider', name: 'service-scheduler-provider' },
  'reputation-platform': { displayName: 'Platform', name: 'reputation-platform'},
  'social-media-provider': { displayName: 'Provider', name: 'social-media-provider'},
  'source-type': { displayName: 'Source Type', name: 'source-type' },
  'trait': { displayName: 'Segment', name: 'trait' },
  'vehicle': { displayName: 'Vehicles', name: 'vehicle'},
  'vehicle-make': { displayName: 'Make', name: 'vehicle-make'},
  'vehicle-class': { displayName: 'Class', name: 'vehicle-class'},
  'vehicle-model': { displayName: 'Model', name: 'vehicle-model'},
  'website-provider': { displayName: 'Provider', name: 'website-provider'},
  'website-metric-type': { displayName: 'Metric', name: 'website-metric-type'},
};

export type ReportSectionType = 'chat' | 'inventory' | 'leads' | 'sales' | 'website' | 'digital-advertising' | 'provider-tools';

export interface Filter {
  type: string;
  selected: FilterValueType[];
  available?: number[];
  removable?: boolean;
  lockable?: boolean;
  locked?: boolean;
  visible?: boolean;
}

export class FilterConfigReport {
  reportName: string;
  filters: string[];
  defaults: string[];
  dateMode?: IDateMode;
  defaultMonthDateMode?: DateMonthModes;
  dateModeToggle?: boolean;
  numberOfMonthsToShow?: number;
  numberOfQuartersToShow?: number;
  quarterDateModeEnabled?: boolean;
  orgTypes?: number[];
  restrictDates?: boolean;
  section?: ReportSectionType;
  disableCalendarMode?: boolean;
  salesCalendarToggleDisabled?: boolean;
}
