export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Shift Digital Hospitality',
  hostName: 'https://analytics-staging.shiftdigitalhospitality.com',
  environmentName: 'staging',
  appName: 'Shift Digital Hospitality',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '65ef2f19-614a-4ced-b3a3-91bf39d503bf'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 13,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1, 4],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#F7971C', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://sdhospitality-analytics-api-demo.azurewebsites.net/api',
  authUri: 'https://sdhospitality-analytics-api-demo.azurewebsites.net/token',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false
};
