import {
  IDefaultTableColumnConfig,
  IDualAxisLineGraphConfig,
  ICardHeaderConfig,
  IHospitalityWebsiteSummaryConfig
} from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class HospitalityWebsiteSummaryConfig implements IHospitalityWebsiteSummaryConfig {
  reportTitle = 'Website Summary';
  public useDefaultComparisonColumns = false;
  public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Metric Explorer',
      iconClass: 'fa-file',
      helpTextKey: constants.helpTextKeys.websiteSummaryHelp,
      helpTextTitle: 'Metric Explorer',
      exportName: 'Metric Explorer - Export',
      metricDisplayModes: ['MOM', 'YOY']
  };

  public graphConfig: IDualAxisLineGraphConfig = {
      xAxisPropertyName: 'date',
      availableMetrics: [
          {
              id: 1,
              displayName: 'Visits',
              propertyName: 'visits',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 2,
              displayName: 'Unique Visitors',
              propertyName: 'uniqueVisitors',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 3,
              displayName: 'Page Views',
              propertyName: 'pageViews',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 4,
              displayName: 'Page Views/Visit',
              propertyName: 'pageViewsPerVisits',
              metricFormatKey: constants.formatKeys.roundToTenth
          },
          {
              id: 5,
              displayName: 'Page Views/Visitor',
              propertyName: 'pageViewsPerUniqueVisitor',
              metricFormatKey: constants.formatKeys.roundToTenth
          },
          {
              id: 6,
              displayName: 'Book Now Clicks',
              propertyName: 'bookNowClicks',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 7,
              displayName: 'Book Now Click Rate',
              propertyName: 'bookNowClickRate',
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          {
              id: 8,
              displayName: 'Bookings',
              propertyName: 'bookingsCompleted',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 9,
              displayName: 'Booking Rate',
              propertyName: 'bookingsCompletedRate',
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          {
              id: 10,
              displayName: 'Avg. Time On Site',
              propertyName: 'avgTimeOnSite',
              metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
          },
          {
              id: 11,
              displayName: 'Form Initiations',
              propertyName: 'formInitiations',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 12,
              displayName: 'Form Submissions',
              propertyName: 'formSubmissions',
              metricFormatKey: constants.formatKeys.localeString
          },
          {
              id: 13,
              displayName: 'Form Submission Rate',
              propertyName: 'formSubmissionRate',
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          {
              id: 14,
              displayName: 'Look to Books',
              propertyName: 'lookingToBooks',
              metricFormatKey: constants.formatKeys.percentageTwoDecimals
          },
          {
              id: 15,
              displayName: 'Length of Stay',
              propertyName: 'lengthOfStay',
              metricFormatKey: constants.formatKeys.roundToTenth
          }, {
              id: 16,
              displayName: 'Room Nights',
              propertyName: 'bookingRoomNights',
              metricFormatKey: constants.formatKeys.roundToTenth
          }, {
              id: 17,
              displayName: 'Revenue',
              propertyName: 'bookedTotalRevenue',
              metricFormatKey: constants.formatKeys.currencyNoCents
          }, {
              id: 18,
              displayName: 'Click To Calls',
              propertyName: 'clickToCalls',
              metricFormatKey: constants.formatKeys.roundToTenth
          }, {
              id: 19,
              displayName: 'Avg. Daily Rate',
              propertyName: 'revenuePerNight',
              metricFormatKey: constants.formatKeys.currencyNoCents
          }, {
              id: 20,
              displayName: 'Add-On Revenue',
              propertyName: 'bookedAddOnRevenue',
              metricFormatKey: constants.formatKeys.currencyNoCents
          }, {
              id: 21,
              displayName: 'Room Searches',
              propertyName: 'roomSearches',
              metricFormatKey: constants.formatKeys.localeString
          }
      ],
      defaultMetricOneId: 1,
      defaultMetricTwoId: 8,
      metricOneColorHexCode: '#000',
      metricTwoColorHexCode: '#F7971C'
  };
  public trendTableMetricKeys: string[] = [
      Enums.websiteMetrics.uniqueVisits.nameKey,
      Enums.websiteMetrics.websiteVisits.nameKey,
      Enums.websiteMetrics.pageViews.nameKey,
      Enums.websiteMetrics.servicePageViews.nameKey,
      Enums.websiteMetrics.pageViewsPerVisit.nameKey,
      Enums.websiteMetrics.formConversionRate.nameKey,
      Enums.websiteMetrics.formSubmissions.nameKey,
      Enums.websiteMetrics.avgTimeOnSite.nameKey,
      Enums.websiteMetrics.phoneCalls.nameKey,
      Enums.websiteMetrics.clickToCalls.nameKey,
      Enums.websiteMetrics.newInventoryDetailViews.nameKey,
      Enums.websiteMetrics.usedInventoryDetailViews.nameKey,
      Enums.websiteMetrics.cpoInventoryDetailViews.nameKey
  ];
  public summaryTableColumns: IDefaultTableColumnConfig[] = [
      {
          show: true,
          header: '',
          columnDef: 'entity',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
      },
      {
          show: false,
          print: true,
          header: 'Property ',
          columnDef: 'displayName',
          isMom: false,
          isYoy: false,
          metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      // Visits
    {
      show: true,
      header: 'Visits',
      columnDef: 'visits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: true,
      header: 'Visits MOM',
      columnDef: 'visitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Bookings',
      columnDef: 'bookingsCompleted',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: true,
      header: 'Bookings MOM',
      columnDef: 'bookingsCompletedMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Room Nights',
      columnDef: 'bookingRoomNights',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
    },
    {
      show: true,
      header: 'Room Nights MOM',
      columnDef: 'bookingRoomNightsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Revenue',
      columnDef: 'bookedTotalRevenue',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currencyNoCents, clickable: false
    },
    {
      show: true,
      header: 'Revenue MOM',
      columnDef: 'bookedTotalRevenueMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Click To Calls',
      columnDef: 'clickToCalls',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: true,
      header: 'Click To Calls MOM',
      columnDef: 'clickToCallsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Look to Books',
      columnDef: 'lookingToBooks',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
    },
    {
        show: true,
        header: 'Look to Books MOM',
        columnDef: 'lookingToBooksMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
    },
    {
      show: true,
      header: 'Booking Rate',
      columnDef: 'bookingsCompletedRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
    },
    {
      show: true,
      header: 'Booking Rate MOM',
      columnDef: 'bookingsCompletedRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
    },
    {
      show: true,
      header: 'Avg. Daily Rate',
      columnDef: 'revenuePerNight',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currencyNoCents, clickable: false
    },
    {
      show: true,
      header: 'Avg. Daily Rate MOM',
      columnDef: 'revenuePerNightMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Length of Stay',
      columnDef: 'lengthOfStay',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
    }, {
      show: true,
      header: 'Length of Stay MOM',
      columnDef: 'lengthOfStayMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Add-On Revenue',
      columnDef: 'bookedAddOnRevenue',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.currencyNoCents, clickable: false
    },
    {
      show: true,
      header: 'Add-On Revenue MOM',
      columnDef: 'bookedAddOnRevenueMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Room Searches',
      columnDef: 'roomSearches',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: true,
      header: 'Room Searches MOM',
      columnDef: 'roomSearchesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: true,
      header: 'Book Now Clicks',
      columnDef: 'bookNowClicks',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: true,
      header: 'Book Now Clicks MOM',
      columnDef: 'bookNowClicksMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    }, {
      show: true,
      header: 'Form Submissions',
      columnDef: 'formSubmissions',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: true,
      header: 'Form Submissions MOM',
      columnDef: 'formSubmissionsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    }, {
      show: true,
      header: 'Page Views/Visit',
      columnDef: 'pageViewsPerVisits',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
    },
    {
      show: true,
      header: 'Page Views/Visit MOM',
      columnDef: 'pageViewsPerVisitsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    }]
}
