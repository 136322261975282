import {
    IDefaultTableColumnConfig,
    IDigAdProviderConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';

export class DigAdProviderConfig implements IDigAdProviderConfig {
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
