

import {
  IWebsiteOverviewBillboardConfig,
  IHospitalityWebsiteOverviewConfig,
  IDefaultTableColumnConfig,
  IWebsiteOverviewKpiConfig,
  ICardHeaderConfig,} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class HospitalityWebsiteOverviewConfig implements IHospitalityWebsiteOverviewConfig {
  public reportTitle = 'Website Overview';
  public benchmarkTitle = 'Properties';
  public useNewMetricCutoffDate = true;
  public useDefaultComparisonColumns = false;
  public newMetricCutoffDate = new Date(2019, 0, 1);
  public chartSeriesColors = ['#000', '#F7971C', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public deviceTypeColors = ['#000', '#F7971C', '#A6A6A6', '#D9D9D9', '#CCCCCC'];
  public referrerTypeColors = ['#F7971C', '#9e2a75', '#5f1675', '#6b45cc', '#0f4ec4', '#266886', '#369e8d', '#209645', '#587e35', '#ccc145', '#cc8b45', '#666666'];
  public referrerQualityColors = ['#847450', '#224197', '#f9bb11', '#c51230'];
  public showPieChart = true;
  public referrerQualityCardHeader: ICardHeaderConfig = {
    title: 'Referrer Quality',
    iconClass: 'fa-star',
    helpTextKey: constants.helpTextKeys.websiteOverviewReferrerQualityHelp,
    helpTextTitle: 'Referrer Quality',
  };
  public referrerTypeCardHeader: ICardHeaderConfig = {
    title: 'Channel',
    iconClass: 'fa-align-left',
    helpTextKey: constants.helpTextKeys.websiteOverviewReferrerTypeHelp,
    helpTextTitle: 'Channel',
  };
  public trafficByDeviceTypeHeader: ICardHeaderConfig = {
    title: 'Traffic By Device',
    iconClass: 'fa-mobile',
    helpTextKey: constants.helpTextKeys.websiteOverviewTrafficByDeviceTypeHelp,
    helpTextTitle: 'Traffic By Device',
  };
  public visitorTrendCardHeader: ICardHeaderConfig = {
    title: 'Trends',
    iconClass: 'fa-bar-chart',
    helpTextKey: constants.helpTextKeys.websiteOverviewVisitorTrendHelp,
    helpTextTitle: 'Visitor Trend',
  };
  public benchmarkCardHeader: ICardHeaderConfig = {
    title: 'Dealer Performance',
    iconClass: 'fa-suitcase',
    helpTextKey: constants.helpTextKeys.websiteOverviewBenchmarkHelp,
    helpTextTitle: 'Benchmark',
    exportName: 'Website Overview - Dealer Performance',
    metricDisplayModes: ['MOM', 'YOY']
  };
  public billboards: IWebsiteOverviewBillboardConfig[] = [
      {
          title: 'Visits',
          subtitle: null,
          iconClass: 'fa-users fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'visits',
          metricMOMPropertyName: 'visitsMOM',
          metricYOYPropertyName: 'visitsYOY',
          metricPreviousMonthPropertyName: 'visitsPreviousMonth',
          metricPreviousMTDPropertyName: 'visitsPreviousMTD',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'visitsMOM',
          metricFormatKey: constants.formatKeys.localeString,
          helpTextKey: constants.helpTextKeys.websiteOverviewVisitsBillboardHelp,
          helpTextTitle: 'Visits',
          showTrendArrow: true
      },
      {
          title: 'Click to Calls',
          subtitle: null,
          iconClass: 'fa-phone fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'clickToCalls',
          metricMOMPropertyName: 'clickToCallsMOM',
          metricYOYPropertyName: 'clickToCallsYOY',
          metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
          metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'clickToCallsMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: constants.helpTextKeys.websiteOverviewClickToCallsBillboardHelp,
          helpTextTitle: 'Click To Calls',
          showTrendArrow: true
      },
      {
          title: 'Bookings',
          subtitle: null,
          iconClass: 'fa-calendar-check-o fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'bookingsCompleted',
          metricMOMPropertyName: 'bookingsCompletedMOM',
          metricYOYPropertyName: 'bookingsCompletedYOY',
          metricPreviousMonthPropertyName: 'bookingsCompletedPreviousMonth',
          metricPreviousMTDPropertyName: 'bookingsCompletedPreviousMTD',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'bookingsCompletedMOM',
          metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
          helpTextKey: constants.helpTextKeys.websiteOverviewBookingsCompletedBillboardHelp,
          helpTextTitle: 'Bookings',
          showTrendArrow: true
      },
      {
          title: 'Revenue',
          subtitle: null,
          iconClass: 'fa-usd fa-primary',
          reverseMetric: false,
          metricCurrentPropertyName: 'bookedRoomRevenue',
          metricMOMPropertyName: 'bookedRoomRevenueMOM',
          metricYOYPropertyName: 'bookedRoomRevenueYOY',
          metricPreviousMonthPropertyName: 'bookedRoomRevenuePreviousMonth',
          metricPreviousMTDPropertyName: 'bookedRoomRevenuePreviousMTD',
          metricPreviousYearPropertyName: null,
          footerRowCount: 1,
          footerTwoLeftLabel: 'MOM',
          footerTwoRightLabel: 'YOY',
          footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
          trendArrowDrivingPropertyName: 'bookedRoomRevenueMOM',
          metricFormatKey: constants.formatKeys.currencyNoCents,
          helpTextKey: constants.helpTextKeys.websiteOverviewBookedRoomRevenueBillboardHelp,
          helpTextTitle: 'Revenue',
          showTrendArrow: true
      },
  ];
  public kpiCardConfig: IWebsiteOverviewKpiConfig = {
    chartTitle: 'Visits & Bookings',
    xAxisPropertyName: 'date',
    metrics: {
      'MetricOne': {
          yAxisTitle: 'Visits',
          displayName: 'Visits',
          pointLabelFormat: null,
          propertyName: 'visits',
          metricFormatKey: null,
          formatter: null
      },
      'MetricTwo': {
          yAxisTitle: 'Bookings',
          displayName: 'Bookings',
          pointLabelFormat: null,
          propertyName: 'bookingsCompleted',
          metricFormatKey: null,
          formatter: null
      }
    }
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
      {
        show: true,
        header: '',
        columnDef: 'entity',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
      },
      {
        show: false,
        print: true,
        header: 'Property ',
        columnDef: 'displayName',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
      },
      // Visits
      {
        show: true,
        header: 'Visits',
        columnDef: 'visits',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: true,
        header: 'Visits MOM',
        columnDef: 'visitsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Bookings',
        columnDef: 'bookingsCompleted',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: true,
        header: 'Bookings MOM',
        columnDef: 'bookingsCompletedMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Room Nights',
        columnDef: 'bookingRoomNights',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
      },
      {
        show: true,
        header: 'Room Nights MOM',
        columnDef: 'bookingRoomNightsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Revenue',
        columnDef: 'bookedTotalRevenue',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.currencyNoCents, clickable: false
      },
      {
        show: true,
        header: 'Revenue MOM',
        columnDef: 'bookedTotalRevenueMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Click To Calls',
        columnDef: 'clickToCalls',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: true,
        header: 'Click To Calls MOM',
        columnDef: 'clickToCallsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Look to Books',
        columnDef: 'lookingToBooks',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
      },
      {
          show: true,
          header: 'Look to Books MOM',
          columnDef: 'lookingToBooksMOM',
          isMom: true,
          isYoy: false,
          metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
      },
      {
        show: true,
        header: 'Booking Rate',
        columnDef: 'bookingsCompletedRate',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
      },
      {
        show: true,
        header: 'Booking Rate MOM',
        columnDef: 'bookingsCompletedRateMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageOneDecimal, clickable: false
      },
      {
        show: true,
        header: 'Avg. Daily Rate',
        columnDef: 'revenuePerNight',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.currencyNoCents, clickable: false
      },
      {
        show: true,
        header: 'Avg. Daily Rate MOM',
        columnDef: 'revenuePerNightMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Length of Stay',
        columnDef: 'lengthOfStay',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
      }, {
        show: true,
        header: 'Length of Stay MOM',
        columnDef: 'lengthOfStayMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Add-On Revenue',
        columnDef: 'bookedAddOnRevenue',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.currencyNoCents, clickable: false
      },
      {
        show: true,
        header: 'Add-On Revenue MOM',
        columnDef: 'bookedAddOnRevenueMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Room Searches',
        columnDef: 'roomSearches',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: true,
        header: 'Room Searches MOM',
        columnDef: 'roomSearchesMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      },
      {
        show: true,
        header: 'Book Now Clicks',
        columnDef: 'bookNowClicks',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: true,
        header: 'Book Now Clicks MOM',
        columnDef: 'bookNowClicksMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      }, {
        show: true,
        header: 'Form Submissions',
        columnDef: 'formSubmissions',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.localeString, clickable: false
      },
      {
        show: true,
        header: 'Form Submissions MOM',
        columnDef: 'formSubmissionsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      }, {
        show: true,
        header: 'Page Views/Visit',
        columnDef: 'pageViewsPerVisits',
        isMom: false,
        isYoy: false,
        metricFormatKey: constants.formatKeys.roundToTenth, clickable: false
      },
      {
        show: true,
        header: 'Page Views/Visit MOM',
        columnDef: 'pageViewsPerVisitsMOM',
        isMom: true,
        isYoy: false,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
      }
    ];
}
